<template>
  <div class="shopdetails-box">
    <div v-if="permissionsTalg">
      <!--面包屑区域-->
      <div class="breadcrumb_box" v-if="!isAlibabaDistributor">
        <span class="breadcrumb_title">{{ $t('key1005296') }}</span>
        <Breadcrumb separator=">">
          <BreadcrumbItem v-for="(item,index) in breadcrumbList" :key="index" :to="changeBreadcrumb(item)">{{ item.title }}</BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div v-if="details">
        <!--商品展示区域-->
        <div class="exhibition-box">
          <div class="exhibition-left">
            <img-zoom ref="imgBox" :imgZoomList="imgZoomList" :details="details"></img-zoom>
          </div>
          <div class="exhibition-right">
            <h2 class="exhibition-title" :title="details.name">{{ details.name }}</h2>
            <!--<p class="shop-describe" :title="details.enName">{{ details.enName }}</p>-->
            <div class="grounding-box">
              <span class="text">SPU：{{ details.ymsSpu }}</span>
              <span class="text">{{ $t('key1005297') }}{{ $uDate.dealTime(details.createdTime, 'fulltime') }}</span>
              <span class="text">{{ $t('key1000608') }}{{ details.deliveryInterval + $t('key1001108') }}</span>
              <span class="text">{{ $t('key1000607') }}{{ details.productServiceTxt }}</span>
            </div>
            <!--当商品停售之后不展示-->
            <template v-if="details.shelveStatus !== 2">
              <!--列表数据-->
              <Table
                class="sku-table"
                highlight-row
                border
                :max-height="300"
                @on-row-click="rowClick"
                :row-class-name="rowClass"
                :columns="tableColumn"
                :data="tableData">
              </Table>
              <!--优惠区域-->
              <div class="discount" v-if="details.productPriceSchemeResultBos">
                <div class="dis-box">
                  <p class="d-item">{{ $t('key1005298') }}</p>
                  <p>{{ $t('key1005299') }}</p>
                </div>
                <div class="dis-box" v-for="item in details.productPriceSchemeResultBos">
                  <p class="d-item">
                    <span class="ic" v-if="item.schemeType === 1">{{ item.schemeValue !== null ? Math.round(item.schemeValue * 100) / 10 : '*' }}{{ $t('key1004461') }}</span>
                    <span class="ic" v-else>
                  {{ $t('key1004462') }}
                  {{ item.schemeValue !== null ? Math.round(item.schemeValue * 100) / 100 : '*' + ' ' + (currencyState ? currencyState : '') }}
                </span>
                    <Tooltip :content="$t('key1005294')" v-if="!item.schemeValue">
                      <Icon type="md-help-circle" class="icon"/>
                    </Tooltip>
                  </p>
                  <p>
                <span v-if="item.endSalesVolume">
                  {{ item.startSalesVolume }} - {{ item.endSalesVolume }}{{ $t('key1003969') }}
                </span>
                    <span v-else>
                  {{ item.startSalesVolume }}{{ $t('key1004463') }}
                </span>
                  </p>
                </div>
              </div>
              <!--操作按钮-->
              <div class="opts-box">
                <Button class="subscribe-btn" type="primary" size="large" v-if="(details.availablePlatforms && details.availablePlatforms.length >0 &&
               getPermission('ymsDistributorProductInfo_publish') && userInfo) ||
               (details.availablePlatforms && details.availablePlatforms.length >0 && !userInfo)
               || fromListing" @click="publishBtn">
                  {{ $t('key1004373') }}
                </Button>
                <Button class="subscribe-btn" type="primary" size="large" ghost v-if="userInfo" @click="generateSalesInfoBtn">{{ $t('key1004472') }}</Button>
                <!--只有已上架的商品才能收藏和下载资料-->
                <template v-if="details.shelveStatus === 1 && details.hasPermission">
                  <Button class="subscribe-btn" type="primary" size="large" ghost @click="downProductData"
                    v-if="((getPermission('ymsDistributorProductInfo_download') && userInfo) || !userInfo) && !fromListing">
                    {{ $t('key1004374') }}
                  </Button>
                  <!--未收藏-->
                  <div class="opts-box" v-if="(details.isSubscription === 0 && getPermission('ymsDistributorMerchantSubscription_subscription')
                 && userInfo) || (details.isSubscription === 0 && (!userInfo || fromListing))">
                    <Icon type="md-heart-outline" size="32" class="cursor" @click="subscription"/>
                    <span class="ml4">{{ $t('key1005300') }}</span>
                  </div>
                  <!--已收藏-->
                  <div class="opts-box" v-if="(details.isSubscription !== 0 && getPermission('ymsDistributorMerchantSubscription_cancelSubscription')
                 && userInfo) || (details.isSubscription !== 0 && (!userInfo || fromListing))">
                    <Icon type="md-heart" size="30" color="#FF5310" class="cursor" @click="unSubscription"/>
                    <span class="ml4">{{ $t('key1004473') }}</span>
                  </div>
                </template>
              </div>
              <!--一键刊登支持以下平台-->
              <div class="support_platform_content" v-if="details.availablePlatforms && details.availablePlatforms.length >0">
                <h3 class="title">{{ $t('key1005301') }}</h3>
                <ul class="support_platform_box">
                  <li class="platformIcon" v-for="(item, index) in details.availablePlatforms" :key="index">
                    <div class="support_platform_item">
                      <img :src="item.iconPath" width="16" height="16" alt="">
                    </div>
                    <span class="support_platform_text">{{ item.platformId }}</span>
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </div>
        <!--商品详情信息-->
        <div class="shopping-content">
          <div class="shop-content-nav">
            <!--优质供应商-->
            <div class="quality-supplier-box" v-if="!fromListing && Object.keys(supplierInfo).length >0">
              <div class="title-box">
                <span :class="languagesVal === 'CN' ? 'title' : 'set_title'">{{ $t('key1005302') }}</span>
              </div>
              <h3 class="supplier-title" :title="supplierInfo.name">{{ supplierInfo.name }}</h3>
              <p class="line-item" v-if="!isAlibabaDistributor">{{ $t('key1005295') + supplierInfo.address }}</p>
              <div class="options-btn" v-if="!isAlibabaDistributor">
                <Button size="small" class="btn" @click="toStoreList">{{ $t('key1004593') }}</Button>
                <Button size="small" class="btn" @click="followStore" :disabled="followTalg">{{ followText }}</Button>
              </div>
            </div>
            <!--销量最高的三个商品-->
            <div class="top3-box-item" v-for="(item, index) in supplierTop3List" :key="index" @click="toshopDetails(item)">
              <div class="img-style">
                <imageBox :src="getThumbnailImage(item.primaryImage)" :animation="true"/>
              </div>
              <h3 class="title" :title="item.name">{{ item.name }}</h3>
              <p class="text" v-if="userInfo">
                <span v-if="item.activityStatus === 1 || item.activityStatus === 3">{{ currencyState + amountHandel(item.activityPrice) }}</span>
                <span class="original_price_style" v-if="item.activityStatus === 1 || item.activityStatus === 3">{{ currencyState + amountHandel(item.minSuggestPrice) }}</span>
                <span v-else>{{ currencyState + ' ' + amountHandel(item.minSuggestPrice) }}</span>
              </p>
              <p class="text" v-else>{{ $t('key1005303') }}</p>
              <!--已收藏-->
              <span class="subscribed-icon" v-if="item.subscribed" style="background-color: #8FC41E;">{{ $t('key1000779') }}</span>
              <!--清仓-->
              <span :class="item.subscribed ?'clearance-icon': 'subscribed-icon'" v-if="item.activityStatus === 1"
                style="background-color: #FF1810;">{{ $t('key1000798') }}</span>
            </div>
          </div>
          <div class="shop-details-box">
            <h2 class="shop-details-title">{{ $t('key1005304') }}</h2>
            <!--物品参数-->
            <div class="desc-box" v-if="ymsCategoryProductAttributeList && ymsCategoryProductAttributeList.length">
              <p class="desc-box-title">{{ $t('key1005305') }}</p>
              <Row>
                <Col :span="12" v-for="(item, index) in ymsCategoryProductAttributeList" :title="item.valueText" :key="index">
                  <div class="desc-box-item">
                    <p class="desc-box-item-title">{{ item.name + '：' }}</p>
                    <p class="desc-box-content">
                      <template v-for="(ele,idx) in item.valueText">
                        <span v-if="ele.proportion" class="mr2">{{ handleAttributeProportion(ele.proportion) + '%' }}</span>
                        <span>{{ ele.value }}</span>
                        <span v-if="idx !== item.valueText.length - 1">、</span>
                      </template>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <!--尺码表-->
            <div class="desc-box" v-if="sizeImagePath">
              <p class="desc-box-title">{{ $t('key1000646') }}</p>
              <div class="size-chart-box">
                <img :src="sizeImagePath" class="szieImage" alt="">
              </div>
            </div>
            <!--供应商建议-->
            <div class="desc-box" v-if="details.description">
              <p class="desc-box-title">{{ $t('key1005306') }}</p>
              <div class="shop-details-center" v-html="details.description"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="center-align">
        <Spin>
          <Icon type="ios-loading" size=30 class="demo-spin-icon-load"></Icon>
        </Spin>
      </div>
    </div>
    <!--无权限展示页-->
    <div v-else class="noPermissions_box">
      <h2 class="title">{{ $t('key1005307') }}</h2>
    </div>
    <!--刊登平台弹窗-->
    <publishingPlatformModal ref="publishingPlatform" @changePlatform="changePlatform"></publishingPlatformModal>
    <!--刊登店铺弹窗-->
    <selectPublishStoreModal ref="selectPublishStore"></selectPublishStoreModal>
  </div>
</template>

<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import imgZoom from '@/components/layout/shopping/common/imgZoom';
import api from '@/api/';
import commonMixin from '@/components/mixin/common_mixin';
import {getDistributorErpConfig} from '@/utils/user';
import {pageJump, amountHandel, setConfigCharacters, isAlibabaDistributor} from "@/utils/common";
import imageBox from '@/components/common/imageBox';
import publishingPlatformModal from "@/components/common/publishingPlatformModal.vue";
import selectPublishStoreModal from "@/components/common/selectPublishStoreModal.vue";
import {VISIBLEAFTERLOGIN} from "@/utils/enum";

export default {
  name: 'shopDetails',
  mixins: [commonMixin],
  data() {
    let v = this;
    return {
      followText: alias2f085dd487c1465383484787b29fef2c.t('key1005308'),
      quality_supplier: require('@/assets/images/quality_supplier.png'),
      breadcrumbList: [],
      specificationColorList: [ // 规格颜色
        '#169BD5', '#009900', '#763eaf', '#cc0033', '#FF9900', '#999999', '#33CC99', '#663300'
      ],
      columns: [
        {
          title: 'SKU',
          key: 'ymsSku',
          minWidth: 150,
          align: 'center'
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000403'),
          key: 'productGoodsSpecifications',
          align: 'center',
          minWidth: 160,
          render(h, params) {
            let specificationsList = [];
            let productGoodsSpecifications = params.row.productGoodsSpecifications || [];
            let originalAttributesData = v.originalAttributesData;
            if (productGoodsSpecifications.length > 0) {
              if (v.languagesVal === 'US') {
                let newList = [];
                productGoodsSpecifications.map((item) => {
                  if (originalAttributesData.length > 0) {
                    originalAttributesData.map((ele) => {
                      if (ele.ymsProductAttributeTemplate.cnName === item.name) {
                        let productAttributeDictionaryBos = ele.ymsProductAttributeTemplate.productAttributeDictionaryBos || [];
                        if (productAttributeDictionaryBos.length > 0) {
                          productAttributeDictionaryBos.map((talg) => {
                            if (talg.cnValue === item.value) {
                              newList.push(talg.usValue);
                            }
                          })
                        }
                      }
                    })
                  }
                });
                if (newList.length > 0) {
                  handleSpecifications(newList);
                }
              }
              // 默认获取中文规格属性值
              else {
                let list = productGoodsSpecifications.map((item, index) => {
                  return item.value
                });
                handleSpecifications(list)
              }
            }

            function handleSpecifications(list) {
              if (list.length > 0) {
                list.map((item, index) => {
                  specificationsList.push(
                    h('span',
                      {
                        style:
                          {
                            color: v.specificationColorList[index]
                          }
                      }, item)
                  );
                  if (index !== productGoodsSpecifications.length - 1) {
                    specificationsList.push(h('span', {class: 'ml5 mr5'}, '•'));
                  }
                })
              }
            }

            return h('div', {class: 'flex flex-wrap justify-content-center'}, specificationsList);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1005309'),
          key: 'length',
          align: 'center',
          minWidth: 130,
          render(h, params) {
            return h('div', params.row.length + '*' + params.row.width + '*' + params.row.height);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000693'),
          key: 'weight',
          align: 'center',
          minWidth: 120,
          render(h, params) {
            return h('div', params.row.weight);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000754'),
          key: 'suggestPrice',
          align: 'center',
          minWidth: 110,
          renderHeader: (h, params) => {
            return h('p', {
              style: {
                whiteSpace: 'nowrap'
              }
            }, alias2f085dd487c1465383484787b29fef2c.t('key1000754') + (v.currencyState ? '(' + v.currencyState + ')' : ''));
          },
          render(h, params) {
            let activityPriceValue = params.row.activityPriceValue;
            let suggestPrice = params.row.suggestPrice;
            let value = activityPriceValue ? activityPriceValue : suggestPrice;
            let text = Object.keys(v.userInfo).length > 0 ? value : VISIBLEAFTERLOGIN;
            return h('span', text);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1000786'),
          key: 'availableNumber',
          align: 'center',
          minWidth: 110,
          render(h, params) {
            let txt = params.row.availableNumber ? params.row.availableNumber : 0;
            let text = Object.keys(v.userInfo).length > 0 ? txt : VISIBLEAFTERLOGIN;
            return h('span', text);
          }
        },
        {
          title: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1000775')}`,
          key: 'ymsAvailableNumber',
          align: 'center',
          minWidth: v.languagesVal === 'CN' ? 135 : 150,
          render(h, params) {
            let txt = params.row.ymsAvailableNumber ? params.row.ymsAvailableNumber : 0;
            let text = Object.keys(v.userInfo).length > 0 ? txt : VISIBLEAFTERLOGIN;
            return h('span', text);
          }
        },
        {
          title: alias2f085dd487c1465383484787b29fef2c.t('key1005310'),
          key: 'usWestNumber',
          align: 'center',
          minWidth: 110,
          render(h, params) {
            let txt = params.row.usWestNumber ? params.row.usWestNumber : 0;
            let text = Object.keys(v.userInfo).length > 0 ? txt : VISIBLEAFTERLOGIN;
            return h('span', text);
          }
        },
      ],
      tableData: [],
      imgZoomList: [],
      prductId: null,
      details: null,
      sizeChartColumns: [],
      sizeChartdata: [],
      supplierInfo: {}, // 优质供应商的基本信息
      supplierTop3List: [], // 供应商销量最高3个商品
      followTalg: false,  // 是否关注供应商店铺
      ymsCategoryProductAttributeList: [],
      cacheImgIndex: 0,
      sizeImagePath: '', // 尺码图
      permissionsTalg: true, // 默认有仓库权限
      originalAttributesData: [], // 属性模板的基础数据
      usWestWarehouseTalg: false, // 默认没有美西仓库权限
    };
  },
  created() {
    this.$store.commit('showHover', true);
    if (this.$route.params.id) {
      this.prductId = this.$route.params.id;
    } else {
      this.gotoError();
    }
    this.getShopDetail();
  },
  computed: {
    // 获取当前登录用户的信息
    userInfo() {
      if (this.$store.state.erpConfig) {
        return this.$store.state.erpConfig.userInfo;
      } else {
        return '';
      }
    },
    fromListing() {
      return this.$store.state.fromListing
    },
    tableColumn() {
      if (this.usWestWarehouseTalg) {
        return this.columns
      } else {
        return this.columns.filter((item) => {
          return item.key !== 'usWestNumber'
        })
      }
    },
    isAlibabaDistributor() {
      return isAlibabaDistributor()
    }
  },
  methods: {
    amountHandel,
    moveHandle() {
      let v = this;
      let _row = document.querySelectorAll('._rowClick');
      for (let i = 0; i < _row.length; i++) {
        _row[i].addEventListener('mouseenter', (ev) => {
          v.rowClick(v.tableData[i], 'no');
        });
      }
      document.querySelector('.sku-table .ivu-table-tbody').addEventListener('mouseleave', () => {
        if (v.cacheImgIndex) {
          v.$refs.imgBox.selectImg(v.imgZoomList[v.cacheImgIndex], v.cacheImgIndex);
        } else {
          v.$refs.imgBox.selectImg(v.imgZoomList[0], 0);
        }
      });
    },
    rowClass() {
      return '_rowClick';
    },
    rowClick(row, cacheRow = 'no') {
      let colorList = row.productGoodsSpecifications.map((item) => {
        return item.value
      });
      let imgPath = '';
      let _data = JSON.parse(JSON.stringify(this.details.productColorImageList));
      _data.reverse().map((item) => {
        if (colorList.includes(item.color)) {
          imgPath = item.path;
        }
      });
      this.imgZoomList.forEach((i, index) => {
        if (i.path === imgPath) {
          this.$refs.imgBox.selectImg(i, index);
          if (cacheRow !== 'no') {
            this.cacheImgIndex = index;
          }
        }
      });
    },
    /**
     * 收藏
     * @author gan
     * @date: 2021/2/7 10:23
     */
    subscription() {
      let v = this;
      getDistributorErpConfig(true).then((res) => {
        let distributorMerchantId = res.merchant.merchantId;
        if (distributorMerchantId) {
          v.axios.post(api.post_ymsDistributorMerchantSubscription_subscription,
            [v.details.ymsProductId]).then(response => {
            if (response.data.code === 0) {
              v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1004382'));
              v.getShopDetail();
              if (v.fromListing && document.referrer) {
                window.parent.postMessage({
                  type: 'subscriptionProduct',
                  ymsProductId: v.details.ymsProductId,
                  from: 'detail',
                  status: 1
                }, '*');
              }
            }
          });
        }
      });
    },
    // 取消收藏
    unSubscription() {
      let v = this;
      getDistributorErpConfig(true).then((res) => {
        let distributorMerchantId = res.merchant.merchantId;
        if (distributorMerchantId) {
          v.axios.post(api.post_ymsDistributorMerchantSubscription_cancelSubscription,
            [v.details.ymsProductId]).then(response => {
            if (response.data.code === 0) {
              v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1005311'));
              v.getShopDetail();
              if (v.fromListing && window.parent) {
                window.parent.postMessage({
                  type: 'subscriptionProduct',
                  ymsProductId: v.details.ymsProductId,
                  from: 'detail',
                  status: 0
                }, '*');
              }
            }
          });
        }
      });
    },
    // 下载资料
    downProductData() {
      let v = this;
      getDistributorErpConfig(true).then((res) => {
        let distributorMerchantId = res.merchant.merchantId;
        if (distributorMerchantId) {
          let query = {
            commodityPictures: ["carousel", "attribute", "sizeTable"],
            isCommodityInformation: 1,
            ymsProductIds: [v.details.ymsProductId]
          }
          v.axios.post(api.post_ymsDistributorProductInfo_download, query, {loading: true, loadingText: alias2f085dd487c1465383484787b29fef2c.t('key1000212')}).then(response => {
            if (response.data.code === 0) {
              let taskNumber = response.data.datas;
              setTimeout(() => {
                pageJump('/distributor.html#/exportTask?operateCode=' + taskNumber, '/yms-distributor-service', '_blank');
              }, 30)
            }
          });
        }
      });
    },
    /**
     * 获取店铺详情
     * @author gan
     * @date: 2021/2/3 16:51
     */
    getShopDetail() {
      let v = this;
      v.imgZoomList = [];
      v.breadcrumbList = [];
      v.permissionsTalg = true;
      v.originalAttributesData = [];
      let config = {
        headers: {
          'Language': v.languagesVal
        }
      }
      let url = '';
      if (v.userInfo.merchantId) {
        url = api.get_ymsProductInfo_shopping + this.prductId + `?distributorMerchantId=${v.userInfo.merchantId}`;
      } else {
        url = api.get_ymsProductInfo_shopping + this.prductId;
      }
      let aliexpressChoiceiconPath = null;
      v.getShoppingPlatformData().then((platformData) => {
        let aliexpressData = platformData.filter((item) => {
          return item.platformId === 'aliexpress';
        });
        if (aliexpressData && aliexpressData.length > 0) {
          aliexpressChoiceiconPath = aliexpressData[0].iconPath;
        }
        v.axios.get(url, config).then(response => {
          let code = response.data.code;
          if (code === 0) {
            let data = response.data.datas;
            if (data) {
              v.getItemAttributesData(data).then((attributesData) => {
                v.originalAttributesData = attributesData.ymsCategoryProductAttributeList || [];
                // 刊登平台icon图标
                let availablePlatforms = data.availablePlatforms || [];
                let newList = [];
                let source = data.source;
                // 商品来源是listing
                if (source === 'listing') {
                  let platformJson = data.platformJson || '';
                  if (platformJson) {
                    v.ymsCategoryProductAttributeList = [];
                    let obj = JSON.parse(platformJson);
                    let productAttributesList = obj.listingPushPlatformInsertProductInfoBo.productAttributes || [];
                    v.ymsCategoryProductAttributeList = productAttributesList.map((item) => {
                      return {
                        name: item.platformAttributeName,
                        valueText: item.values
                      }
                    })
                  }
                }
                // 一键刊登支持的平台
                if (availablePlatforms.length > 0) {
                  availablePlatforms.map((ele) => {
                    // 速卖通全托管平台
                    if (ele.platformId === 'aliexpress') {
                      let obj = {
                        platformId: 'aliexpressChoice',
                        iconPath: aliexpressChoiceiconPath
                      }
                      newList.push(obj);
                    }
                    platformData.map((item) => {
                      if (ele.platformId === item.platformId || ele.platformName === item.name || ele.platformName === item.platformId) {
                        ele.iconPath = item.iconPath;
                      }
                    });
                  });
                  data.availablePlatforms = [...availablePlatforms, ...newList];
                }
                v.details = data;
                v.handleBreadcrumbData(v.details);
                // 处理尺码图的数据
                let sizePathTalg = false;
                v.sizeImagePath = '';
                let productSizeImageList = v.details.productSizeImageList;
                if (productSizeImageList && productSizeImageList.length > 0) {
                  productSizeImageList.map((item) => {
                    // 默认展示ukSize的尺码图，ukSize 没有尺码图，则取cnSize的尺码图
                    if (item.languageType === 'ukSize') {
                      if (item.imagePath) {
                        v.sizeImagePath = item.imagePath;
                        sizePathTalg = false;
                      } else {
                        sizePathTalg = true;
                      }
                    }
                    if (sizePathTalg && item.languageType === 'cnSize') {
                      v.sizeImagePath = item.imagePath;
                    }
                  });
                }
                let supplierMerchantId = v.details.supplierMerchantId;
                let ymsProductId = v.details.ymsProductId;
                if (supplierMerchantId) {
                  v.getSupplierInfo(supplierMerchantId);
                  v.getSupplierTop3(supplierMerchantId, ymsProductId);
                  v.isAssociatedStore(supplierMerchantId);
                }

                v.productServiceList.map((item) => {
                  if (item.value === v.details.productService) {
                    v.details.productServiceTxt = item.name;
                  }
                });
                if (!v.details) {
                  v.$router.push({path: '/index'});
                }
                let productGoodsList = response.data.datas.productGoodsList || [];
                let list = [];
                if (productGoodsList.length > 0) {
                  productGoodsList.map((item) => {
                    item.suggestPrice = amountHandel(item.suggestPrice);
                    if (item.status === 1) {
                      item.orgin_suggestPrice = item.suggestPrice;
                      list.push(item);
                    }
                  });
                  v.tableData = list;
                } else {
                  v.tableData = [];
                }
                let productImageList = v.details.productImageList || [];
                let productColorImageList = v.details.productColorImageList || [];
                let new_arr = [...productImageList, ...productColorImageList];
                if (new_arr) {
                  new_arr.map(i => {
                    if (i.path) {
                      if (i.path.includes('http') || i.path.includes('https')) {
                        v.imgZoomList.push(i);
                      }
                    }
                  });
                }
                setTimeout(v.moveHandle, 0);
              });
            }
          } else if (code === 111291) {
            v.permissionsTalg = false;
          }
        });
      });
    },
    // 获取物品属性的基础数据
    getItemAttributesData(details) {
      let v = this;
      let ymsProductCategoryId = details.ymsProductCategoryId;
      let productAttributeResultList = details.productAttributeResultList || [];
      return new Promise(resolve => {
        if (ymsProductCategoryId) {
          v.axios.get(api.get_ymsProductCategory_shopping + `${ymsProductCategoryId}`).then(response => {
            if (response.data.code === 0) {
              let data = response.data.datas;
              if (data) {
                if (data.ymsCategoryProductAttributeList && data.ymsCategoryProductAttributeList.length > 0) {
                  v.ymsCategoryProductAttributeList = [];
                  // 获取商品的基础属性
                  let list = data.ymsCategoryProductAttributeList.filter(item => item.attributeType === 0);
                  let newList = list.map((item) => {
                    return item.ymsProductAttributeTemplate
                  });
                  if (v.isAlibabaDistributor) {
                    function handleData(item) {
                      let values = item.values || [];
                      let valueText = [];
                      if (values.length > 0) {
                        values.map((talg) => {
                          if (talg.value) {
                            valueText.push({
                              value: talg.value,
                              proportion: talg.proportion
                            });
                          }
                        })
                      }
                      item['valueText'] = v.uniqueFunc(valueText);
                    }

                    productAttributeResultList.map((item) => {
                      newList.map((ele) => {
                        if (item.ymsProductAttributeTemplateId === ele.ymsProductAttributeTemplateId) {
                          item['name'] = v.languagesVal === 'CN' ? ele.cnName : ele.usName;
                          handleData(item);
                        } else {
                          item['name'] = item.ymsProductAttributeTemplateId;
                          handleData(item);
                        }
                      })
                    });
                    v.ymsCategoryProductAttributeList = productAttributeResultList;
                  } else {
                    let typeArr = [alias2f085dd487c1465383484787b29fef2c.t('key1002556'), alias2f085dd487c1465383484787b29fef2c.t('key1000461')];
                    let attributeRequireArr = [alias2f085dd487c1465383484787b29fef2c.t('key1000227'), alias2f085dd487c1465383484787b29fef2c.t('key1000228'), alias2f085dd487c1465383484787b29fef2c.t('key1000229')];
                    let chooseTypeArr = [alias2f085dd487c1465383484787b29fef2c.t('key1000456'), alias2f085dd487c1465383484787b29fef2c.t('key1000457')];
                    if (newList.length > 0) {
                      newList.map((item) => {
                        item['name'] = v.languagesVal === 'CN' ? item.cnName : item.usName;
                        item['attributeRequireText'] = attributeRequireArr[item.attributeRequire];
                        if (item.type === 1) {
                          item['typeText'] = typeArr[item.type] + '(' + chooseTypeArr[item.chooseType] + ')';
                        } else {
                          item['typeText'] = typeArr[item.type];
                        }
                        item['propertyValues'] = null;
                        item['inputValue'] = '';
                        if (productAttributeResultList.length > 0) {
                          productAttributeResultList.forEach((ele) => {
                            if (item.ymsProductAttributeTemplateId === ele.ymsProductAttributeTemplateId) {
                              item.valueText = [];
                              let values = ele.values || [];
                              let valueText = [];
                              if (values.length > 0) {
                                values.map((item) => {
                                  if (item.value) {
                                    valueText.push({
                                      value: item.value,
                                      proportion: item.proportion
                                    });
                                  }
                                })
                              }
                              if (ele.type === 1) {
                                item['propertyValues'] = v.uniqueFunc(valueText, 'value');
                                item['customValues'] = []; // 自定义属性的value值
                                if (item.productAttributeDictionaryBos) {
                                  let ymsProductAttributeDictionaryIdList = [];
                                  item.productAttributeDictionaryBos.forEach((talg) => {
                                    ymsProductAttributeDictionaryIdList.push(talg.ymsProductAttributeDictionaryId)
                                    if (item.propertyValues && item.propertyValues.length > 0) {
                                      item.propertyValues.map((k) => {
                                        if (k.value === talg.ymsProductAttributeDictionaryId) {
                                          if (v.languagesVal === 'US') {
                                            item.valueText.push({
                                              value: talg.usValue,
                                              proportion: k.proportion
                                            });
                                          } else {
                                            item.valueText.push({
                                              value: talg.cnValue,
                                              proportion: k.proportion
                                            });
                                          }
                                        }
                                      });
                                    }
                                  });
                                  // 处理自定义属性的value
                                  if (ymsProductAttributeDictionaryIdList.length > 0) {
                                    if (item.propertyValues && item.propertyValues.length > 0) {
                                      item.propertyValues.map((ele) => {
                                        if (!ymsProductAttributeDictionaryIdList.includes(ele.value)) {
                                          item['customValues'].push({
                                            value: ele.value,
                                            proportion: ele.proportion
                                          })
                                        }
                                      });
                                      let valuesList = [...item.valueText, ...item.customValues];
                                      item.valueText = valuesList;
                                    }
                                  }
                                }
                              } else {
                                item['valueText'] = v.uniqueFunc(valueText);
                              }
                            }
                          });
                        }
                      });
                      v.ymsCategoryProductAttributeList = newList;
                    }
                  }
                }
                resolve(data);
              }
            }
          });
        }
      });
    },
    // 处理属性值百分比
    handleAttributeProportion(proportion) {
      let value = Number(proportion) || 0;
      let val = (value * 100).toFixed(2);
      if (val % 1 === 0) {
        val = val.slice(0, -3);
      }
      return val;
    },
    resetDetail() {
      this.details = null;
      this.tableData = [];
      this.imgZoomList = [];
    },
    toStoreList() {
      let lang = 'zh-CN';
      if (localStorage.getItem('lang')) {
        lang = localStorage.getItem('lang');
      }
      if (this.fromListing) {
        window.location.href = this.supplierInfo.supplierSecondLevelDomain + `?lang=${lang}`;
      } else {
        window.open(this.supplierInfo.supplierSecondLevelDomain + `?lang=${lang}`, '_blank');
      }
    },
    // 关注店铺
    followStore() {
      let v = this;
      getDistributorErpConfig(true).then((res) => {
        let distributorMerchantId = res.merchant.merchantId;
        if (distributorMerchantId) {
          if (!v.followTalg) {
            let query = `?ymsDistributorMerchantId=${distributorMerchantId}&ymsSupplierMerchantId=${v.details.supplierMerchantId}`;
            v.axios.post(api.post_ymsSupplierMerchant_addAssociatedStore + query).then(response => {
              if (response.data.code === 0) {
                v.$Message.success(alias2f085dd487c1465383484787b29fef2c.t('key1000084'));
                v.getShopDetail();
                v.followText = alias2f085dd487c1465383484787b29fef2c.t('key1005312');
              }
            });
          }
        }
      });
    },
    // 查询当前当前供应商店铺是否被关注
    isAssociatedStore(supplierMerchantId) {
      let v = this;
      let query = `?ymsDistributorMerchantId=${v.userInfo.merchantId}&ymsSupplierMerchantId=${supplierMerchantId}`;
      v.axios.get(api.get_ymsSupplierMerchant_isAssociatedStore + query).then(response => {
        if (response.data.code === 0) {
          v.followTalg = response.data.datas;
          v.followText = v.followTalg ? alias2f085dd487c1465383484787b29fef2c.t('key1005312') : alias2f085dd487c1465383484787b29fef2c.t('key1005308');
        }
      });
    },
    // 获取供应商基本信息数据
    getSupplierInfo(supplierMerchantId) {
      let v = this;
      let talg = true;
      v.supplierInfo = {};
      let ymsProductIdList = [];
      // 判断当前商品是否参加活动且开启展示供应商信息
      let ongoingActivitiesData = sessionStorage.getItem('ongoingActivitiesData');
      if (ongoingActivitiesData) {
        let activitiesList = JSON.parse(ongoingActivitiesData);
        if (activitiesList.length > 0) {
          activitiesList.map((item) => {
            let ymsProductIds = item.ymsProductIds || [];
            ymsProductIdList.push(...ymsProductIds);
          });
          // 不参加活动的商品，要默认展示供应商信息
          if (ymsProductIdList.length > 0) {
            if (!ymsProductIdList.includes(v.prductId)) {
              talg = true;
            } else {
              talg = false;
              activitiesList.map((item) => {
                let ymsProductIds = item.ymsProductIds || [];
                if (ymsProductIds.includes(v.prductId)) {
                  if (item.isShowSupplierInfo === 1) {
                    talg = true;
                  }
                }
              });
            }
          }
        }
      }
      if (talg) {
        v.axios.get(api.get_ymsSupplierMerchant + supplierMerchantId).then(response => {
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data) {
              let newData = data.ymsSupplierMerchantDetail || {};
              newData = Object.assign({}, data, newData);
              newData.name = v.handleMultilingualFields(data.name);
              v.$store.commit('supplierInfo', newData);
              let arr = ['name', 'address'];
              arr.map((item) => {
                if (newData[item] === null) {
                  newData[item] = '';
                }
              });
              v.supplierInfo = newData;
              // 判断是否有美西仓库
              let ymsSupplierAscriptionWarehouses = data.ymsSupplierAscriptionWarehouses || [];
              v.usWestWarehouseTalg = ymsSupplierAscriptionWarehouses.some(item => item.code === 'US001');
            }
          }
        });
      } else {
        v.supplierInfo = {};
      }
    },
    // 获取供应商销量最高3个商品
    getSupplierTop3(supplierMerchantId, ymsProductId) {
      let v = this;
      v.supplierTop3List = [];
      let query = {
        orderBy: 'totalSoldQuantity', // 总销量
        supplierMerchantId: supplierMerchantId,
        activityStatus: 0, // 活动状态 0无 1清仓 2促销 3热卖
        pageNum: 1,
        pageSize: 4
      };
      let dataList = [];
      if (v.userInfo.merchantId) {
        query.distributorMerchantId = v.userInfo.merchantId;
      }
      let config = {
        headers: {
          'Language': v.languagesVal
        }
      }
      v.axios.post(api.post_ymsProductInfo_shopping_query, query, config).then(response => {
        if (response.data.code === 0) {
          let data = response.data.datas;
          let list = data.list || [];
          if (list.length > 0) {
            list.map((item) => {
              if (item.ymsProductId !== ymsProductId) {
                dataList.push(item);
              }
            });
            v.supplierTop3List = dataList.slice(0, 3).map(i => {
              i.orgin_minSuggestPrice = i.minSuggestPrice;
              return i;
            });
          }
        }
      });
    },
    // 查看销量最高的商品详情
    toshopDetails(data) {
      const {href} = this.$router.resolve({
        path: `/shopDetails/${data.ymsProductId}`
      });
      if (this.fromListing) {
        this.resetDetail();
        this.prductId = data.ymsProductId;
        this.getShopDetail();
      } else {
        window.open(href, '_blank');
      }
    },
    // 一键刊登
    publishBtn() {
      let availablePlatforms = this.details.availablePlatforms.filter((item) => {
        return item.platformId !== 'aliexpressChoice'
      });
      let source = this.details.source;
      let platformJson = this.details.platformJson;
      if (source === 'listing') {
        if (platformJson) {
          let obj = JSON.parse(platformJson);
          let platformIds = obj.platformIds || [];
          this.$refs['publishingPlatform'].initPublishingPlatformData(platformIds, this.details.ymsProductCategoryId, source);
        }
      } else {
        this.$refs['publishingPlatform'].initPublishingPlatformData(availablePlatforms, this.details.ymsProductCategoryId);
      }
    },
    // 选择刊登平台之后
    changePlatform(data) {
      let type = 0;
      if (this.$store.state.erpConfig) {
        type = this.$store.state.erpConfig.merchant.listingStatus === '1' ? 1 : 0; // 刊登类型 0:YMS上架工具 1:通途刊登系统
      }
      this.$refs['selectPublishStore'].initSelectPublishStoreData(data, this.details.ymsProductId, type);
    },
    // 生成售卖资料
    generateSalesInfoBtn() {
      let type = 0;
      if (this.$store.state.erpConfig) {
        type = this.$store.state.erpConfig.merchant.listingStatus === '1' ? 1 : 0; // 刊登类型 0:YMS上架工具 1:通途刊登系统
      }
      this.generateSalesInfoFun('single', this.details.ymsProductId, this.userInfo.merchantId, type);
    },
    // 处理面包屑的路径
    changeBreadcrumb(data) {
      let path = '';
      if (!data.talg) {
        path = `/shopList?ymsProductCategoryId=${data.ymsProductCategoryId}`
      } else {
        path = data.path;
      }
      return path;
    },
    // 处理面包屑的数据
    handleBreadcrumbData(data) {
      let v = this;
      v.breadcrumbList = [];
      let obj = {path: '/index', title: alias2f085dd487c1465383484787b29fef2c.t('key1005158'), talg: true};
      let ymsClassifyList = JSON.parse(localStorage.getItem('classificationData')) || v.$store.state.classificationData;
      let ymsProductCategoryIds = [];
      if (ymsClassifyList && ymsClassifyList.length > 0) {
        function treeToList(tree, list = []) {
          tree.forEach(node => {
            list.push(node)
            node.children && treeToList(node.children, list)
          })
          return list
        }

        let originalData = treeToList(ymsClassifyList);
        // 获取当前分类的数据
        let newList = originalData.filter((item) => {
          if (item.ymsProductCategoryId === data.ymsProductCategoryId) {
            return item;
          }
        });
        if (newList.length > 0) {

          function handleData(list) {
            originalData.map((ele) => {
              list.map((item) => {
                if (item.parentCode === ele.productCategoryCode) {
                  ymsProductCategoryIds.unshift(ele.ymsProductCategoryId);
                  handleData([ele]);
                }
              });
            });
          }

          handleData(newList)

          ymsProductCategoryIds = [...ymsProductCategoryIds, data.ymsProductCategoryId];
          ymsProductCategoryIds.forEach(i => {
            originalData.forEach(j => {
              if (i === j.ymsProductCategoryId) {
                j.title = j.name;
                v.breadcrumbList.push(j);
              }
            });
          });
        }
      } else {
        let arr = data.productCategoryNavigation ? data.productCategoryNavigation.split('->') : [];
        if (arr.length > 0) {
          arr.map((item) => {
            v.breadcrumbList.push({
              title: item,
              talg: true,
              path: null
            });
          });
        }
      }
      v.breadcrumbList.unshift(obj);
    }
  },
  beforeDestroy() {
    let dom = document.getElementsByClassName('mouse-cover-canvas');
    if (dom) {
      for (let i = 0; i < dom.length; i++) {
        dom[i].style.display = 'none';
      }
    }
  },
  components: {
    imgZoom,
    imageBox,
    publishingPlatformModal,
    selectPublishStoreModal
  }
};
</script>

<style>
.ivu-table td.size-column {
  background-color: #ff6600 !important;
  color: #fff;
}
</style>
<style lang="less" scoped>
@import "src/assets/styles/shopping/common";

.desc-box {
  border: 1px solid #EEEEEE;
  padding: 15px 20px;
  margin: 20px;

  .desc-box-item {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    margin-bottom: 10px;

    .desc-box-item-title {
      color: #000;
      font-weight: bold;
    }

    .desc-box-content {
      color: #666;
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

.desc-box-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

//.desc-box-content {
//  overflow: hidden;
//  white-space: nowrap;
//  text-overflow: ellipsis;
//  margin-bottom: 10px;
//  padding-right: 10px;
//  color: #333333;
//}

.shopdetails-box {
  width: 1200px;
  margin: 0 auto;
  min-height: 400px;

  .breadcrumb_box {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;

    .breadcrumb_title {
      font-size: 14px;
      font-family: 'PingFangSC-Regular';
      color: #000;
      opacity: 0.5;
    }

    /deep/ .ivu-breadcrumb-item-separator {
      color: #515a6e;
      margin: 0 4px 0 0;
    }
  }

  .exhibition-box {
    background-color: #fff;
    padding: 20px;
    min-height: 512px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    .exhibition-left {
      width: 380px;
    }

    .exhibition-right {
      width: 750px;

      .exhibition-title {
        width: 100%;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #1D1D1D;
        margin-bottom: 8px;
      }

      .shop-describe {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #1D1D1D;
        .ellipsis(1)
      }

      .grounding-box {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: @subject_color;
          margin-right: 20px;
        }
      }

      .sku-table {
        margin-bottom: 15px;

        /deep/ .ivu-table-header thead tr th {
          padding: 0;
          height: 30px;
          line-height: 30px;
          font-size: 13px;
          color: #777777;
          background-color: #FFF8F6;
        }
      }

      .opts-box {
        display: flex;
        align-items: center;
      }

      .support_platform_content {
        .title {
          font-size: 13px;
          color: #333;
          font-weight: bold;
          margin: 20px 0 8px 0;
        }

        .support_platform_box {
          display: flex;
          flex-wrap: wrap;

          .platformIcon {
            margin-bottom: 15px;
            display: flex;
            align-items: center;

            .support_platform_item {
              width: 30px;
              height: 30px;
              background-color: #F2F2F2;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .support_platform_text {
              margin: 0 20px 0 8px;
            }
          }
        }
      }

      .subscribe-btn {
        min-width: 140px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        cursor: pointer;
        margin-right: 13px;
        border-radius: 1px;
      }
    }
  }

  .shopping-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .shop-content-nav {
      width: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 50px;

      .quality-supplier-box {
        width: 100%;
        background-color: #fff;
        padding-bottom: 15px;
        margin-bottom: 15px;

        .title-box {
          width: 202px;
          height: 50px;
          background-image: url("../../../../assets/images/quality_supplier.png");
          background-repeat: no-repeat;
          background-position: -1px -1px;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;

          .title {
            font-size: 17px;
            font-weight: bold;
            margin-left: 16px;
            color: #C2973F;
          }

          .set_title {
            font-weight: bold;
            color: #C2973F;
            font-size: 12px;
            margin-left: 50px;
          }
        }

        .supplier-title {
          margin: 12px auto 15px;
          font-size: 13px;
          color: #1D1D1D;
          font-weight: bold;
          .ellipsis(1);
          padding: 0 16px;
        }

        .line-item {
          color: #777777;
          font-size: 13px;
          margin-bottom: 12px;
          padding: 0 16px;
        }

        .options-btn {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-top: 20px;
          padding: 0 16px;

          .btn {
            color: #5C5C5C;
            font-size: 13px;
            height: 30px;
            line-height: 30px;

            &:hover {
              color: #fff;
              background-color: #FD5425;
            }
          }

          .ivu-btn[disabled] {
            background-color: #f7f7f7;
            border-color: #dcdee2;
            color: #c5c8ce;
          }
        }
      }

      .top3-box-item {
        cursor: pointer;
        width: 200px;
        padding: 15px;
        background-color: #fff;
        border: 1px solid transparent;
        box-sizing: border-box;
        position: relative;

        .img-style {
          width: 170px;
          height: 170px;
          overflow: hidden;
        }

        .title {
          font-family: PingFang SC;
          color: #333;
          font-size: 14px;
          text-align: center;
          .ellipsis(1);
          margin: 10px auto;
        }

        .text {
          text-align: center;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #FD5425;
        }

        .original_price_style {
          color: #8D8D8D;
          text-decoration: line-through;
          padding-left: 10px;
          font-size: 12px;
          font-weight: normal;
        }

        &:hover {
          border: 1px solid @subject_color;
          box-sizing: border-box;
        }
      }
    }

    .shop-details-box {
      width: 985px;
      background-color: #fff;
      margin-bottom: 50px;

      .shop-details-title {
        height: 50px;
        line-height: 50px;
        padding-left: 19px;
        border-bottom: 1px solid #EEEEEE;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #1D1D1D;
        box-sizing: border-box;
      }

      .shop-details-center {
        text-align: left;
        padding: 10px 0px;
        min-height: 35px;
        background-color: #fff;

        /deep/ img, /deep/ table {
          display: block;
          width: 100% !important;
          height: auto;
        }

        /deep/ p img {
          display: inline-block;
          width: 100% !important;
          height: auto;
        }
      }

      .size-chart-box {
        .szieImage {
          width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }

  .shop-details-box-hide {
    visibility: hidden;
  }

  .noPermissions_box {
    width: 100%;
    min-height: 490px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;

    .title {
      color: #333;
      font-size: 14px;
      padding-top: 150px;
    }
  }
}

.demo-spin-icon-load {
  animation: ani-demo-spin 1s linear infinite;
}

@keyframes ani-demo-spin {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.demo-spin-col {
  height: 100px;
  position: relative;
  border: 1px solid #eee;
}

.center-align {
  padding-bottom: 30px;
}

.discount {
  display: flex;
  background-color: #F5F5F5;
  padding: 5px 12px 12px 12px;
  margin-bottom: 15px;

  .dis-box {
    margin-right: 50px;
  }

  .d-item {
    height: 40px;
    display: flex;
    align-items: center;

    .ic {
      font-size: 22px;
      color: #FD5425;
    }

    .icon {
      font-size: 18px;
      margin-left: 5px;
      color: #FD5425;
    }
  }
}
</style>
